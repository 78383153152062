import React from 'react'
import { promisify } from 'util'
import { func, object, bool } from 'prop-types'

const options = (method, body) => ({
  headers: { 'content-type': 'application/json' },
  method,
  body: JSON.stringify(body)
})

const create = ({ Component, submitUrl, buildSchema, formId }) => {
  class Submitter extends React.Component {
    constructor(props) {
      super(props)
      this.schema = buildSchema()
      this.state = {
        waiting: false,
        validationError: {},
        serverError: {},
        serverResponse: '',
        data: this.schema.makeDefault(),
        results: []
      }
      this.componentProps = {
        handleOnChange: this.onChange.bind(this),
        handleOnFileChange: this.onFileChange.bind(this),
        handleOnSubmit: this.onSubmit.bind(this),
        changeFormState: this.changeFormState.bind(this)
      }
      this.scrollRef = this.scrollRef.bind(this)

      this.successMessage = 'Form has been submitted'
      this.ref = null
    }

    componentDidMount() {
      this.onBeforeFormRender()
    }

    changeFormState(state) {
      this.setState({
        data: { ...this.state.data, ...state.data },
        validationError: state.validationError || {}
      })
    }

    onBeforeFormRender() {
      const { assignDefaultValues } = this.props
      this.setState({ data: { ...assignDefaultValues } })
    }

    scrollRef(ref) {
      return (this.ref = ref)
    }

    scrollToRef(ref) {
      if (!ref) return
      return ref.scrollIntoView(false)
    }

    createUrl(url) {
      return window.location.origin + url
    }

    getCheckBoxValues(e) {
      const defaultChecked = e.currentTarget.defaultChecked
      const checkboxState = this.state.data[e.currentTarget.name]
      if (defaultChecked && !checkboxState) {
        return { value: false }
      }

      const values =
        (this.state.data && this.state.data[e.currentTarget.name]) || {}
      values[e.currentTarget.value] = !values[e.currentTarget.value]
      return values
    }

    onChange(e, wordLimit) {
      let targetValue = e.currentTarget.value
      const isWordLimit = wordLimit && wordLimit > 0

      if (e.target.type === 'checkbox') {
        targetValue = this.getCheckBoxValues(e)
      }

      if (e.target.type === 'textarea' && isWordLimit) {
        targetValue = targetValue.split(' ').splice(0, wordLimit).join(' ')
        e.currentTarget.value = targetValue
      }

      this.setState({
        data: { ...this.state.data, [e.currentTarget.name]: targetValue }
      })
    }

    onFileChange(e) {
      const name = e.target.name
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file, file.name)

      fetch(`${submitUrl}file/${formId}`, {
        headers: { 'x-file-type': file.type },
        method: 'POST',
        credentials: 'same-origin',
        body: formData
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.id) {
            this.setState({
              data: { ...this.state.data, [name]: data.id }
            })
          } else {
            const errors = {}
            errors[name] = data.error
            this.setState({
              serverError: { errors },
              serverResponse: data.error
            })
          }
        })
    }

    clearErrorMessages() {
      const { setSubmitMessage } = this.props
      this.setState({
        successMessage: '',
        serverError: {},
        validationError: {}
      })
      setSubmitMessage && setSubmitMessage('')
    }

    async onAfterSubmission() {
      const { onSubmission } = this.props
      this.setState({
        successMessage: this.successMessage
      })
      if (onSubmission) await onSubmission()
      return true
    }

    createBody() {
      const { additionalBody } = this.props
      return { data: this.state.data, ...additionalBody }
    }

    async onSubmit(e) {
      e.preventDefault()
      try {
        this.clearErrorMessages()
        const errors = await promisify(this.schema.validate)(this.state.data)
        if (Object.keys(errors).length > 0) {
          this.scrollToRef(this.ref)
          return this.setState({ validationError: errors })
        }
        const body = this.createBody()
        this.setState({ waiting: true })
        const res = await fetch(
          this.createUrl(submitUrl),
          options('POST', body)
        )
        if (res.status === 200) {
          if (!this.props.persistLoadingOnSubmission) {
            this.setState({ waiting: false })
          }
          this.scrollToRef(this.ref)
          return await this.onAfterSubmission()
        }
        this.setState({ waiting: false })
        const response = await res.json()
        const { status, validationErrors } = response || {}
        this.setState({
          serverError: { errors: {} },
          serverResponse: status,
          validationErrors
        })
        return this.scrollToRef(this.ref)
      } catch (error) {
        this.scrollToRef(this.ref)
        this.setState({
          serverError: error,
          waiting: false
        })
      }
    }

    render() {
      return (
        <div ref={this.scrollRef}>
          <Component {...this.componentProps} {...this.props} {...this.state} />
        </div>
      )
    }
  }

  Submitter.propTypes = {
    setSubmitMessage: func,
    onSubmission: func,
    assignDefaultValues: object,
    additionalBody: object,
    persistLoadingOnSubmission: bool
  }

  return Submitter
}

export default create
